import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { UfMfValueModel } from '../../models';
import Api from '../../services/api';
import { getConstructionCodes } from './commonThunk';

export interface ConstructionCodesSaveDataModel<T> {
  uf: T;
  mf: T;
}

export interface ConstructionCodesRequestPayload {
  project_id: number;
  handle_for: 'need-form' | 'deliverable' | 'content-plan' | 'all-exist' | 'specification';
  table: 'uf' | 'mf';
  parent_id: number | null;
  search: string | null;
  building_ids?: number[] | null;
  callback?: (data?: any) => void;
}

export interface ConstructionCodesResponsePayload {
  target: UfMfValueModel[];
  tree: UfMfValueModel[];
  type: 'uf' | 'mf';
}

export interface InitialState {
  constructionCodes: any;
  constructionCodesTree: any;
  constructionCodesLoading: typeof Api.requestStatus;
  constructionCodesLoadingType: 'uf' | 'mf' | null;
}

const initialState: InitialState = {
  constructionCodes: null,
  constructionCodesTree: null,
  constructionCodesLoading: Api.initialStatus,
  constructionCodesLoadingType: null,
};

const clientsProjectsReducer = createSlice({
  initialState,
  name: 'commonReducer',
  reducers: {},
  extraReducers: builder => {
    builder
      /**
       * getConstructionCodes
       */
      .addCase(getConstructionCodes.pending, (state, { meta }: PayloadAction<null, string, { arg: ConstructionCodesRequestPayload }>) => {
        state.constructionCodesLoading = Api.requestStatus;
        state.constructionCodesLoadingType = meta.arg.table;
      })
      .addCase(getConstructionCodes.fulfilled, (state, { payload }: PayloadAction<ConstructionCodesResponsePayload>) => {
        const currentState = current(state);

        state.constructionCodes = {
          ...currentState.constructionCodes,
          [payload.type]: payload.target,
        };
        state.constructionCodesTree = {
          ...currentState.constructionCodesTree,
          [payload.type]: payload.tree,
        };
        state.constructionCodesLoading = Api.successStatus;
      })
      .addCase(getConstructionCodes.rejected, (state, { payload }) => {
        state.constructionCodesLoading = Api.failStatus;
      });
  },
});

// const {
//
// } = clientsProjectsReducer.actions;
//
// export {
//     setClient,
//     clearClient,
//     getClientProjects,
//     getClientProjectsSuccess,
//     getProject,
//     getProjectSuccess,
//     getClientFromProject,
//     getClientFromProjectSuccess,
//     setCurrentBuilding,
// };

export default clientsProjectsReducer.reducer;
