import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { InputBase, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setKpiDisplaySettings,
  setDisplaySettings as setDisplaySettingsToStore,
  setAdvancedDisplaySettings as setAdvancedDisplaySettingsToStore,
} from '../../../../store/authData/authoriztionSlice';
import { RootState } from '../../../../store/reducers';
import { CheckBox } from '../../../controls';
import { defaultAdvancedSettings, defaultDisplaySettings, defaultSettings } from '../../../../constants/toolbar/defaultDisplaySettings';
import AccessHelper from '../../../../helpers/AccessHelper';
import { AdvancedDisplaySettingsModel, DisplaySettingsModel } from './settingsPanel.types';
import { MenuPropsSingle, MenuPropsSingleSelected } from '../../Select/MenuPropsSingle';
import { MenuPropsSingleValue } from '../../../../constants/menuPropsSelect';

interface IncDecButtons {
  displaySetting: string;
  isDisableDuration?: boolean;
}

export const initialDisplaySettings: DisplaySettingsModel = {
  markers: true,
  schedule: true,
  tooltips: true,
  flowlines: true,
  legends: window.innerWidth >= 1201,
  addMarkers: true,
  endpoints: true,
  duration: true,
  paths: true,
  points: true,
  cursorLine: false,
  privateMarkers: true,
  statistics: window.innerWidth >= 1201,
  deliverables: true,
  deliverableLinked: true,
  declinePoints: true,
};

export const initialAdvancedDisplaySettings: AdvancedDisplaySettingsModel = {
  level_height: 120,
  flowline_style: 'dashed',
  multi_level_type: 'show-faded',
  endpoints_width: 8,
  duration_width: 1,
  paths_width: 2,
  points_radius: 3,
  decline_radius: 3,
};

const flowlineSelectValues = [
  { value: 'dashed', title: 'Dashed line' },
  { value: 'solid', title: 'Solid line' },
];

const multiLevelValues = [
  { value: 'show', title: 'Show' },
  { value: 'show-faded', title: 'Show faded' },
  { value: 'hide', title: 'Hide' },
];

const selectStyles = {
  ...MenuPropsSingle,
  PaperProps: {
    style: {
      maxHeight: '419px',
      overflowY: 'auto',
      maxWidth: '400px',
      minWidth: '100px',
      backgroundColor: 'var(--background-main)',
      borderRadius: '6px',
      zIndex: 3,
    },
  },
};

let timer;

export default function DisplaySettingBox(props) {
  const dispatch = useDispatch();
  const kpiDisplaySettings = useSelector((state: RootState) => state.authorization.kpiDisplaySettings);
  const userInfo = useSelector((state: RootState) => state.authorization.userInfo);
  const displaySettings = useSelector(
    (state: RootState) => state.authorization.kpiDisplaySettings?.display_settings || initialDisplaySettings,
  );

  const [advancedDisplaySettings, setAdvancedDisplaySettings] = useState(
    kpiDisplaySettings?.advanced_display_settings || initialAdvancedDisplaySettings,
  );

  const isAccess = useMemo(() => {
    return AccessHelper.isAccessCheck(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (kpiDisplaySettings?.advanced_display_settings) {
      setAdvancedDisplaySettings(kpiDisplaySettings.advanced_display_settings);
    }
  }, [kpiDisplaySettings]);

  function setSettings(dSettings: any, advancedDisplaySettings: any) {
    const data = {
      data: {
        display_settings: dSettings || displaySettings,
        advanced_display_settings: advancedDisplaySettings,
      },
    };

    dispatch(setKpiDisplaySettings(data));
  }

  const handleChangeDisplaySettings = (e, v) => {
    const split = v.split(',');
    const isSolo = split.length === 1;

    if (isSolo) {
      const newDisplaySettings = {
        ...displaySettings,
        [v]: e.target.checked,
      };

      dispatch(setDisplaySettingsToStore(newDisplaySettings));
      dispatch(setAdvancedDisplaySettingsToStore(advancedDisplaySettings));

      props.stateChanged(v, e.target.checked);

      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setSettings(newDisplaySettings, advancedDisplaySettings);
      }, 500);
    } else {
      //Multiple changed
      const newDisplaySettings = {
        ...displaySettings,
        [split[0]]: e.target.checked,
        [split[1]]: e.target.checked,
        [split[2]]: e.target.checked,
      };

      dispatch(setDisplaySettingsToStore(newDisplaySettings));
      dispatch(setAdvancedDisplaySettingsToStore(advancedDisplaySettings));

      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setSettings(newDisplaySettings, advancedDisplaySettings);
      }, 500);

      props.stateChanged(split[0], e.target.checked);
      props.stateChanged(split[1], e.target.checked);
      props.stateChanged(split[2], e.target.checked);
    }
  };

  const handleChangeAdvancedDisplaySettings = (e, v) => {
    let value;
    if (v === 'flowline_style' || v === 'multi_level_type') {
      value = e.target.value;
    }

    if (v === 'level_height') {
      value = +e.target.value;
    }

    if (v === 'endpoints_width' || v === 'duration_width' || v === 'points_radius') {
      value = +e.target.value > 15 ? 15 : +e.target.value < 1 ? 1 : +e.target.value;
    }

    if (v === 'paths_width') {
      value = +e.target.value > 30 ? 30 : +e.target.value < 1 ? 1 : +e.target.value;
    }

    const newAdvancedDisplaySettings = {
      ...advancedDisplaySettings,
      [v]: value,
    };

    setAdvancedDisplaySettings(newAdvancedDisplaySettings);
    dispatch(setAdvancedDisplaySettingsToStore(newAdvancedDisplaySettings));

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSettings(displaySettings, newAdvancedDisplaySettings);
    }, 500);

    if (v === 'level_height' && value < 100) {
      props.stateChanged(v, 100);
      return;
    }
    props.stateChanged(v, value);
  };

  const handleAdvancedSettingsBlur = (e, v) => {
    let value;

    if (v === 'level_height' && +advancedDisplaySettings.level_height < 100) {
      value = 100;
    } else {
      value = advancedDisplaySettings.level_height;
    }

    const newAdvancedDisplaySettings = {
      ...advancedDisplaySettings,
      [v]: value,
    };

    setAdvancedDisplaySettings(newAdvancedDisplaySettings);
    props.stateChanged(v, value);
  };

  const handleResetSettings = () => {
    dispatch(setDisplaySettingsToStore(defaultDisplaySettings));
    dispatch(setAdvancedDisplaySettingsToStore(defaultAdvancedSettings));

    // setAdvancedDisplaySettings(defaultAdvancedSettings);
    props.stateChanged('setAllSettings', defaultSettings);
  };

  const IncDecButtons = ({ displaySetting, isDisableDuration }: IncDecButtons) => {
    const changeValue = type => {
      const resultValue =
        type === 'dec'
          ? advancedDisplaySettings[displaySetting] > 1
            ? +advancedDisplaySettings[displaySetting] - 1
            : 1
          : displaySetting === 'paths_width'
          ? +advancedDisplaySettings[displaySetting] < 30
            ? +advancedDisplaySettings[displaySetting] + 1
            : 30
          : +advancedDisplaySettings[displaySetting] < 15
          ? +advancedDisplaySettings[displaySetting] + 1
          : 15;

      const newAdvancedDisplaySettings = {
        ...advancedDisplaySettings,
        [displaySetting]: resultValue,
      };

      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        setSettings(null, newAdvancedDisplaySettings);
      }, 500);

      setAdvancedDisplaySettings(newAdvancedDisplaySettings);
      props.stateChanged(displaySetting, resultValue);
    };

    return (
      <>
        {typeof isDisableDuration === 'boolean' ? (
          <div className={'incDecButtons'}>
            <button disabled={!isDisableDuration} onClick={() => changeValue('inc')} className={'incDecButtons__button'}>
              <span>+</span>
            </button>
            <button disabled={!isDisableDuration} onClick={() => changeValue('dec')} className={'incDecButtons__button'}>
              <span>-</span>
            </button>
          </div>
        ) : (
          <div className={'incDecButtons'}>
            <button onClick={() => changeValue('inc')} className={'incDecButtons__button'}>
              <span>+</span>
            </button>
            <button onClick={() => changeValue('dec')} className={'incDecButtons__button'}>
              <span>-</span>
            </button>
          </div>
        )}
      </>
    );
  };

  const checkDisableDurationLineHandler = () => {
    return displaySettings.duration;
  };

  return (
    <div className="toolbox">
      <div className={'toolbox__header -displaySettings'}>
        <span className={'toolbox__headerText'}>Display options</span>
        <div className={'toolbox__resetButton'}>
          <span onClick={handleResetSettings}>Reset to default</span>
        </div>
      </div>
      <div className={'toolbox__settings -displaySettings'}>
        <div className={'displaySettings'}>
          <div className={'settingsItem'}>
            <CheckBox
              onChange={e => handleChangeDisplaySettings(e, 'markers')}
              checked={displaySettings.markers}
              className={'settingsCheckBox'}
              id={'markers'}
            >
              <span>Markers</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.schedule}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'schedule')}
              id={'Schedules'}
            >
              <span>Schedules</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.legends}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'legends')}
              id={'Legend'}
              disabled={window.innerWidth < 1200}
            >
              <span>Legend</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.flowlines}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'flowlines')}
              id={'Flowlines'}
            >
              <span>Flowlines</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.tooltips}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'tooltips')}
              id={'Tooltips'}
            >
              <span>Tooltips</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.statistics}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'statistics')}
              id={'Statistics'}
              disabled={window.innerWidth < 1200}
            >
              <span>Statistics</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.deliverables}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'deliverables')}
              id={'Deliverables'}
            >
              <span>Deliverables</span>
            </CheckBox>
          </div>
        </div>
        <div className={'advancedDisplaySettings'}>
          <div className={'inputSettingsItem'}>
            <span className={'settingsLabel'}>Max. level height:</span>
            <InputBase
              name={'title'}
              className={`settingsInput`}
              value={advancedDisplaySettings.level_height || ''}
              onBlur={e => handleAdvancedSettingsBlur(e, 'level_height')}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'level_height')}
              type={'number'}
              endAdornment={<span className={'endAdornment'}>px</span>}
            />
          </div>
          <div className={'inputSettingsItem'}>
            <span className={'settingsLabel'}>Flowline style:</span>
            <Select
              value={advancedDisplaySettings.flowline_style}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'flowline_style')}
              displayEmpty={false}
              MenuProps={selectStyles}
              name="flowline_style"
              className={'settingsSelect'}
            >
              {flowlineSelectValues.map(({ value, title }, index) => {
                const valueStyle = value === advancedDisplaySettings.flowline_style ? MenuPropsSingleSelected : MenuPropsSingleValue;

                return (
                  <MenuItem
                    className={'menuItem'}
                    key={index}
                    value={value}
                    style={{
                      ...valueStyle,
                      fontSize: '12px',
                      lineHeight: '14px',
                    }}
                  >
                    <em>{title}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={'inputSettingsItem'}>
            <span className={'settingsLabel'}>Multi-level NFs:</span>
            <Select
              value={advancedDisplaySettings.multi_level_type || 'hide'}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'multi_level_type')}
              displayEmpty={false}
              MenuProps={selectStyles}
              name="multi-level-nfs"
              className={'settingsSelect'}
            >
              {multiLevelValues.map(({ value, title }, index) => {
                const valueStyle = value === advancedDisplaySettings.multi_level_type ? MenuPropsSingleSelected : MenuPropsSingleValue;

                return (
                  <MenuItem
                    className={'menuItem'}
                    key={index}
                    value={value}
                    style={{
                      ...valueStyle,
                      fontSize: '12px',
                      lineHeight: '14px',
                    }}
                  >
                    <em>{title}</em>
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.addMarkers}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'addMarkers')}
              id={'Disable-adding-markers'}
              disabled={!isAccess}
            >
              <span>Disable adding markers</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.privateMarkers}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'privateMarkers')}
              id={'Show-private-markers'}
            >
              <span>Show private markers</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.cursorLine}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'cursorLine')}
              id={'Show cursor'}
              disabled={window.innerWidth < 1200}
            >
              <span>Show cursor</span>
            </CheckBox>
          </div>
          <div className={'settingsItem'}>
            <CheckBox
              checked={displaySettings.deliverableLinked}
              className={'settingsCheckBox'}
              onChange={e => handleChangeDisplaySettings(e, 'deliverableLinked')}
              id={'Show deliverable linked NFs'}
            >
              <span>Show deliverable linked NFs</span>
            </CheckBox>
          </div>
        </div>
        <div className={'advancedDisplaySettings'}>
          <div className={'inputSettingsItem -checkbox'}>
            <div>
              <CheckBox
                checked={displaySettings.paths}
                className={'settingsCheckBox'}
                onChange={e => handleChangeDisplaySettings(e, 'paths')}
                id={'Path-lines'}
              >
                <span>Path lines</span>
              </CheckBox>
            </div>
            <InputBase
              name={'title'}
              className={`settingsInput -styledArrows`}
              value={advancedDisplaySettings.paths_width || ''}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'paths_width')}
              type={'number'}
              endAdornment={<IncDecButtons displaySetting={'paths_width'} />}
            />
          </div>
          <div className={'inputSettingsItem -checkbox'}>
            <div>
              <CheckBox
                checked={displaySettings.points}
                className={'settingsCheckBox'}
                onChange={e => handleChangeDisplaySettings(e, 'points')}
                id={'Points'}
              >
                <span>Points</span>
              </CheckBox>
            </div>
            <InputBase
              name={'title'}
              className={`settingsInput -styledArrows`}
              value={advancedDisplaySettings.points_radius || ''}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'points_radius')}
              type={'number'}
              endAdornment={<IncDecButtons displaySetting={'points_radius'} />}
            />
          </div>
          <div className={'inputSettingsItem -checkbox'}>
            <div>
              <CheckBox
                disabled={!checkDisableDurationLineHandler()}
                checked={displaySettings.endpoints}
                className={'settingsCheckBox'}
                onChange={e => {
                  handleChangeDisplaySettings(e, 'endpoints');
                }}
                id={'Endpoints'}
              >
                <span>Endpoints</span>
              </CheckBox>
            </div>
            <InputBase
              name={'title'}
              className={`settingsInput -styledArrows`}
              value={advancedDisplaySettings.endpoints_width || ''}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'endpoints_width')}
              type={'number'}
              endAdornment={<IncDecButtons isDisableDuration={checkDisableDurationLineHandler()} displaySetting={'endpoints_width'} />}
            />
          </div>
          <div className={'inputSettingsItem -checkbox'}>
            <div>
              <CheckBox
                checked={displaySettings.duration}
                className={'settingsCheckBox'}
                onChange={e => {
                  handleChangeDisplaySettings(e, 'duration,endpoints,declinePoints');
                }}
                id={'Duration-lines'}
              >
                <span>Duration lines</span>
              </CheckBox>
            </div>
            <InputBase
              name={'title'}
              className={`settingsInput -styledArrows`}
              value={advancedDisplaySettings.duration_width || ''}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'duration_width')}
              type={'number'}
              endAdornment={<IncDecButtons isDisableDuration={checkDisableDurationLineHandler()} displaySetting={'duration_width'} />}
            />
          </div>
          <div className={'inputSettingsItem -checkbox'}>
            <div>
              <CheckBox
                disabled={!checkDisableDurationLineHandler()}
                checked={displaySettings.declinePoints}
                className={'settingsCheckBox'}
                onChange={e => handleChangeDisplaySettings(e, 'declinePoints')}
                id={'Decline'}
              >
                <span>Decline points</span>
              </CheckBox>
            </div>
            <InputBase
              name={'title'}
              className={`settingsInput -styledArrows`}
              value={advancedDisplaySettings.decline_radius}
              onChange={e => handleChangeAdvancedDisplaySettings(e, 'decline_radius')}
              type={'number'}
              endAdornment={<IncDecButtons isDisableDuration={checkDisableDurationLineHandler()} displaySetting={'decline_radius'} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
