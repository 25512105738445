import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Routes } from './routes/Router';

import { logout } from './store/authData/authoriztionSlice';
import { LOGOUT_EVENT } from './constants/events';
import { initTheme } from './constants/themes/initTheme';
import { lightTheme } from './constants/themes/lightTheme/lightTheme';

function App() {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    window.addEventListener(LOGOUT_EVENT, handleLogout);

    return () => {
      window.removeEventListener(LOGOUT_EVENT, handleLogout);
    };
  }, [handleLogout]);

  // Plug start
  // const getIp = async () => {
  //   dispatch(getIPv4(await publicIpv4.v4().then()));
  // };
  //
  // useEffect(() => {
  //   getIp();
  // }, []);
  // Plug end

  const initThemStyles = () => {
    initTheme(lightTheme);
  };

  initThemStyles();

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
