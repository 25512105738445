import { KeyValueModel } from '../models';
import { list_columns } from '../constants/NeedListConstants';

export const isEmptyObject = (obj: object | null) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const convertFilterNameForApi = (filters: KeyValueModel<any>) => {
  const newFormData = {};
  Object.keys(filters || {}).map(data => {
    const findData = list_columns.find(d => d.filterField === data);
    if (findData?.filteredField) {
      newFormData[findData.filteredField] = filters[data];
      if (findData.filteredField === 'ufc') {
        newFormData[findData.filteredField] = filters[data].map(d => d.id);
      }
    } else {
      newFormData[data] = filters[data];
    }
  });
  return newFormData;
};
