import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConstructionCodesRequestPayload, ConstructionCodesResponsePayload } from './commonSlice';
import Api from '../../services/api';

export const getConstructionCodes = createAsyncThunk<ConstructionCodesResponsePayload, ConstructionCodesRequestPayload>(
  'commonReducer/getConstructionCodes',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { callback, ...data } = payload;

      const response = await Api.post(`uni-master-forms`, data);

      if (callback) {
        callback();
      }

      return { ...response.data, type: data.table };
    } catch (error) {
      // dispatch(setShowErrorData({ ...error.data, statusCode: error.status }));
      return rejectWithValue(error);
    }
  },
);
